<template>
    <div>
        <b-modal class="rounded-lg" size="sm" v-model="abrir" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="icono text-center p-2" align="center">
                    <i class="fa fa-warning fa-3x"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Salir!</strong></span><br>
            </div>
            <div class="d-flex justify-content-center text-center mt-1">
                <p class="subttitle-modal">¿Estás seguro de querer cerrar sesión?</p>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <b-overlay
                :show="busy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                >
                    <button @click="logout()" class="btn-modal-success border-0 px-3 pb-2 pt-1" :disabled="busy">Finalizar</button>
                </b-overlay>
                <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
            </div>
        </b-modal>
    </div>    
</template>

<script>

import { url } from "../../../settings";

export default {
    name: 'logout',
    props: ['abrir'],

    data: () => ({
        busy: false,
    }),

    methods: {
        cerrarModal(){
            this.$emit("cerrarModalLogout");
        },

        async logout() {
            this.busy = true
            try {
                const res = await fetch(`${url}/api/auth/logout`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {        
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                this.busy = false
                localStorage.removeItem('token');
                window.location.href="/"
            } catch (error) {
                this.busy = false
                window.location.href="/"
            } 
        },

        onEscapeKeyUp(event) {
            if (this.abrir===true) {
                if (event.which === 27) {
                    this.cerrarModal()        
                }
            }
        },

        onEnterKeyUp(event) {
            if (this.abrir===true) {
                if (event.which === 13) {
                    this.logout()      
                }
            }
        }
    },
    mounted(){
        window.addEventListener('keyup', (evt) => {
            this.onEscapeKeyUp(evt)
        })
        window.addEventListener('keyup', (evt) => {
            this.onEnterKeyUp(evt)
        })
    },
    destroyed() {  
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
    }
}
</script>

<style scoped>
    .fa-warning {
        color: #d15460;
    }
</style>