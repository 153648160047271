<template>
    <div>
        <b-modal class="rounded-lg" size="sm" v-model="abrir" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="icono text-center p-2" align="center">
                    <i class="fa fa-warning fa-3x"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Aviso!</strong></span><br>
            </div>
            <div class="d-flex justify-content-center text-center mt-1">
                <p><small>Su sesión ha expirado. Inicia sesión nuevamente</small></p>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <button class="btn-modal-success border-0 px-3 pb-2 pt-1" @click="cerrarModal()">Finalizar</button>
            </div>
        </b-modal>
    </div>    
</template>

<script>
export default {
    name: 'SesionExpirada',
    props: ['abrir'],

    data: () =>({
        id: ''
    }),
    
    methods: {
        cerrarModal(){
            this.$emit("cerrarModalSesExp");
        }
    },
}
</script>

<style scoped>
    .fa-warning {
        color: #d15460;
    }
    
    .btn_finalizar {
        border-radius: 5px;
        background-color: #689aaf;
        color: white;
    }

    .btn_cancelar {
        border-radius: 5px;
        border: 1px solid #c9888e;
        background-color: white;
        color: #c9888e;
    }
</style>